import styled from "styled-components";
import { colors } from "./constants";
import Row from "./Row";

export const Separator = styled.div`
  width: 100%;
  height: ${({ size }) => (size ? size : 10)}px;
`;

export const AppContainer = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;

`;

export const Container = styled.div`
  padding: 10px;
`;

export const RoundContainer = styled.div`
  padding: 16px;
  border-radius: 16px;
  background-color: #ffffff;
`;

export const Centered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const Info = styled.div`
  padding: 8px;
  font-size: 13px;
`;

export const InfoGray = styled.div`
  font-size: 13px;
  color: #777777;
`;

export const TagRow = styled(Row)`
  gap: 4px;
  flex-wrap: wrap;
`;

export const Tag = styled.div`
  font-size: 13px;
  color: ${colors.primary};
  border: solid 1px ${colors.primary};
  border-radius: 16px;
  padding: 4px 8px;
  white-space: nowrap;
  transition: all 0.15s ease-in;

  ${({ clickable }) =>
    clickable
      ? `
  cursor: pointer;
  &:hover {
    background-color: ${colors.primaryHover};
    color: #ffffff;
  }
`
      : ""}
`;
