import React from "react";
import styled from "styled-components";
import Row from "./Row";
import Col from "./Col";
import { colors } from "./constants";

const StyledFooter = styled(Row)`
  background-color: ${colors.gray};
  padding: 32px 16px;
  height: 50px;
  align-items: center;

  button {
    white-space: nowrap;
  }
`;

const Footer = ({ children, ...props }) => {
  return <StyledFooter {...props}>{children}</StyledFooter>;
};

export default Footer;
