import { useEffect, useRef, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import CookieConsent from "react-cookie-consent";
import "./App.scss";
import { colors } from "./components/layout/constants";
import { AppContainer, Centered, Separator } from "./components/layout/Other";
import Col from "./components/layout/Col";
import Row from "./components/layout/Row";
import Button from "./components/layout/Button";
import Footer from "./components/layout/Footer";

const Header = styled.div`
  position: sticky;
  top: 0;
  z-index: 1112;
  background-color: ${colors.primary};
  padding: 16px;
  left: 0;
  right: 0;
`;

const Screen = styled.div`
  height: 80vh;
  background-color: ${colors.primary};
  padding: 40px;
  //background-image: linear-gradient(${colors.primary} 0%, ${colors.primary} 70%, ${colors.primaryHover} 100%);

  @media only screen and (max-width: 900px) {
    padding: 40px 10px;
  }
`;

const Screen2 = styled.div`
  min-height: 80vh;
  background-color: #ffffff;
  padding: 40px;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
    padding: 40px 10px;
    height: unset;
  }
`;

const Screen3 = styled.div`
  min-height: 80vh;
  background-color: #ff7f50;
  padding: 40px;
  display: flex;
  align-items: center;
  div {
    color: #ffffff !important;
  }

  @media only screen and (max-width: 900px) {
    flex-direction: column;
    padding: 40px 10px;
    height: unset;
    justify-content: center;
  }
`;

const BigText = styled.div`
  position: absolute;
  color: #ffffff11;
  font-size: 14.8vw;
  font-weight: bold;
  left: 0;
  top: calc(80vh - 17vw);
`;

const BigLogo = styled.div`
  color: #ffffff;
  font-size: ${({ small }) => (small ? "24px" : "48px")};
  font-weight: bold;
  padding: 8px 24px;

  transition: font-size 0.3s ease-in;
  /*
  background-image: linear-gradient(
    90deg,
    #ffffff00 0%,
    blueviolet 10%,
    blueviolet 50%,
    blueviolet 90%,
    #ffffff00 100%
  );
  */
  display: inline-block;

  @media only screen and (max-width: 900px) {
    font-size: 24px;
    padding-left: 0;
  }
`;

const JumboText = styled.div`
  text-align: center;
  font-size: 3.5vw;
  color: #ffffff;
  font-weight: bold;
  margin-top: 20vh;

  @media only screen and (max-width: 900px) {
    font-size: 32px;
  }
`;

const JumboTitle = styled.div`
  text-align: center;
  font-size: 8vh;
  color: ${colors.primaryHover};
  font-weight: bold;

  @media only screen and (max-width: 900px) {
    font-size: 6vh;
  }
`;

const Underlined = styled.span`
  background-color: blueviolet;
  color: #cccccc;
  padding: 0 8px;
`;

const Screenshot = styled.img`
  max-height: 60vh;
  border-radius: 24px;
  overflow: hidden;
  box-shadow: blue 0px 0px 0px 2px inset, rgb(31, 193, 27) 10px 10px,
    rgb(255, 217, 19) 20px 20px, rgb(255, 156, 85) 30px 30px,
    rgb(255, 85, 85) 40px 40px;
`;

const Screenshot2 = styled.img`
  max-height: 60vh;
  border-radius: 24px;
  overflow: hidden;
  //box-shadow: blueviolet 0px 0px 0px 8px;
  width: 100%;
`;

const ScreenshotCol = styled(Col)`
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: 900px) {
    justify-content: center;
  }
`;

const DescriptionCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Description = styled.div`
  text-align: center;
  margin: 20px;
  font-size: 24px;
  font-weight: 200;
`;

const Super = styled.span`
  font-weight: bold;
  text-shadow: -4px 4px #ef3550, -8px 8px #f48fb1, -12px 12px #7e57c2,
    -16px 16px #2196f3, -20px 20px #26c6da, -24px 24px #43a047,
    -28px 28px #eeff41, -32px 32px #f9a825, -36px 36px #ff5722;
`;

const Super2 = styled.span`
  text-decoration: underline;
  text-decoration-color: #f9a825;
`;

const JumboButton = styled(Button)`
  font-size: 2vw;
  color: #ffffff;
  border-radius: 3vw;
  padding: 1vw 2vw;

  @media only screen and (max-width: 900px) {
    font-size: 24px;
    border-radius: 24px;
    padding: 8px 16px;
  }
`;

const Price = styled.div`
  background-image: linear-gradient(
    90deg,
    #ffffff00 0%,
    blueviolet 10%,
    blueviolet 50%,
    blueviolet 90%,
    #ffffff00 100%
  );
  color: #ffffff;
  padding: 16px 32px;
  font-size: 4vw;
  margin: 16px;
`;

const PriceExplanation = styled.div`
  color: #ffffff;
  padding: 16px 32px;
  font-size: 1.2vw;
  font-weight: bold;

  @media only screen and (max-width: 900px) {
    font-size: 24px;
  }
`;

const InfoRow = styled(Row)`
  @media only screen and (max-width: 900px) {
    flex-direction: column !important;
  }
`;

const BigMenuWrapper = styled.div`
  display: block;

  @media only screen and (max-width: 900px) {
    display: none;
  }
`;

const SmallMenuWrapper = styled.div`
  display: none;

  @media only screen and (max-width: 900px) {
    display: block;
  }
`;

const FloatingMenu = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.primary}dd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  padding: 32px;
  z-index: 1111;
`;

const FloatingMenuButton = styled.div`
  position: absolute;
  right: 16px;
  top: 20px;
`;

const FooterRow = styled(Row)`
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;

const GlobalStyles = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const catchphrases = [
  <>
    beautiful <Underlined>about you</Underlined> links, as simple as they get.
  </>,
  <>
    simple <Underlined>about you</Underlined> links, as beautiful as you.
  </>,
  <>
    you'll never want another <Underlined>about you</Underlined> link again.
  </>,
];

const c = catchphrases[Math.floor(Math.random() * catchphrases.length)];

function App() {
  const [scrolled, setScrolled] = useState(false);
  const [showFloatingMenu, setShowFloatingMenu] = useState(false);

  const featuresRef = useRef(null);
  const pricingRef = useRef(null);

  const scrollTo = (ref) => {
    ref?.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      if (window.scrollY > 40) {
        setScrolled(true);
      }
      if (window.scrollY === 0) {
        setScrolled(false);
      }
    });
  }, [setScrolled]);

  return (
    <div>
      <Header>
        <Row>
          <Col flex={1}>
            <BigLogo small={scrolled}>presenceof.me</BigLogo>
          </Col>
          <Col style={{ margin: "auto 0" }}>
            <BigMenuWrapper>
              <Row gap={16}>
                <Button
                  status="text-light"
                  outline
                  onClick={() => scrollTo(featuresRef)}
                >
                  features
                </Button>
                <Button
                  status="text-light"
                  outline
                  onClick={() => scrollTo(pricingRef)}
                >
                  pricing
                </Button>
                <Button status="danger">get started</Button>
              </Row>
            </BigMenuWrapper>
            <SmallMenuWrapper>
              <Button
                status="info"
                outline
                onClick={() => setShowFloatingMenu(!showFloatingMenu)}
              >
                menu
              </Button>
            </SmallMenuWrapper>
            {showFloatingMenu && (
              <>
                <FloatingMenu>
                  <FloatingMenuButton>
                    <Button
                      status="info"
                      outline
                      onClick={() => setShowFloatingMenu(!showFloatingMenu)}
                    >
                      close
                    </Button>
                  </FloatingMenuButton>
                  <Button
                    status="info"
                    onClick={() => {
                      scrollTo(featuresRef);
                      setShowFloatingMenu(false);
                    }}
                  >
                    features
                  </Button>
                  <Button
                    status="info"
                    onClick={() => {
                      scrollTo(pricingRef);
                      setShowFloatingMenu(false);
                    }}
                  >
                    pricing
                  </Button>
                  <Button status="danger">get started</Button>
                </FloatingMenu>
                <GlobalStyles />
              </>
            )}
          </Col>
        </Row>
      </Header>

      <Screen>
        <BigText>presenceof.me</BigText>
        <AppContainer>
          <Centered>
            <JumboText>{c}</JumboText>
            <Separator size={24} />
            <JumboButton status="danger" style={{ zIndex: 1 }}>
              see how it looks
            </JumboButton>
          </Centered>
        </AppContainer>
      </Screen>
      <Screen2 ref={featuresRef}>
        <AppContainer>
          <InfoRow>
            <DescriptionCol flex={4}>
              <JumboTitle>create your own page</JumboTitle>
              <Description>
                easily shareable with others, make your presenceof.me page your
                single source of truth.{" "}
                <Button outline>see an example here</Button>
              </Description>
            </DescriptionCol>
            <ScreenshotCol flex={4}>
              <Screenshot src={require("./assets/screenshots/2.png")} />
            </ScreenshotCol>
          </InfoRow>
        </AppContainer>
      </Screen2>
      {/*#7bed9f*/}
      <Screen2 style={{ backgroundColor: "#eeeeee" }}>
        <AppContainer>
          <InfoRow gap={64} style={{ flexDirection: "row-reverse" }}>
            <DescriptionCol flex={6}>
              <JumboTitle>
                <Super>super</Super> customizable
              </JumboTitle>
              <Description>
                do whatever you want and need with your presenceof.me page
              </Description>
            </DescriptionCol>
            <ScreenshotCol flex={4}>
              <Screenshot2 src={require("./assets/screenshots/3.png")} />
            </ScreenshotCol>
          </InfoRow>
        </AppContainer>
      </Screen2>

      <Screen2>
        <AppContainer>
          <InfoRow gap={64}>
            <DescriptionCol flex={6}>
              <JumboTitle>
                add <Super2>as many links</Super2> as you want
              </JumboTitle>
              <Description>easily add, update and reorder them</Description>
            </DescriptionCol>
            <ScreenshotCol flex={4}>
              <Screenshot2 src={require("./assets/screenshots/4.png")} />
            </ScreenshotCol>
          </InfoRow>
        </AppContainer>
      </Screen2>

      <Screen3 ref={pricingRef}>
        <AppContainer>
          <Centered>
            <JumboTitle>pricing</JumboTitle>
            <Price>free</Price>
            <PriceExplanation>
              that's it. we will probably add paid accounts at some point, but
              the current features will always be free.
            </PriceExplanation>
          </Centered>
        </AppContainer>
      </Screen3>

      <Screen>
        <AppContainer>
          <Centered>
            <JumboText>
              get started for free, no credit card required.
            </JumboText>
            <Separator size={20} />
            <JumboButton status="danger">get started</JumboButton>
          </Centered>
        </AppContainer>
      </Screen>

      <Footer>
        <AppContainer>
          <FooterRow>
            <Col flex={8}>
              <Row gap={16}>
                <Button status="text" small>
                  privacy policy
                </Button>
                <Button status="text" small>
                  terms of use
                </Button>
                <Button status="text" small>
                  contact us
                </Button>
              </Row>
            </Col>
            <Col flex={2}>
              <Button status="text" small>
                Copyright &copy; Colorcode 2022.
              </Button>
            </Col>
          </FooterRow>
        </AppContainer>
      </Footer>

      <CookieConsent
        location="bottom"
        buttonText="works for me"
        cookieName="presence_of_me_cookie_consent"
        style={{ background: "blueviolet" }}
        buttonStyle={{
          backgroundColor: "#f0932b",
          border: "solid 2px #f0932b",
          color: "#ffffff",
          fontSize: "16px",
          padding: "8px 16px",
          borderRadius: "32px",
        }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </div>
  );
}

export default App;
