export const colors = {
  primary: "#4834d4", // "#45aaf2", //"#e77f67",
  primaryHover: "#686de0", // "#2d98da", //"#e15f41",
  danger: '#f0932b',
  dangerHover: '#ffbe76',
  info: '#f5f6fa',
  infoHover: '#dcdde1',
  gray: '#a5b1c2',
  lightGray: '#f5f6fa'
};
