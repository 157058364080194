import React from "react";
import styled from "styled-components";

const StyledCol = styled.div`
  flex: ${({ flex }) => flex};
  ${({ centered }) => (centered ? "display: flex; align-items: center;" : "")};
`;

const Col = ({ children, flex, centered, ...props }) => {
  return (
    <StyledCol flex={flex} centered={centered} {...props}>
      {children}
    </StyledCol>
  );
};

export default Col;
